import useMedRequestFiltering from '@/lib/api/med-requests/filtering'
import usePatientOrderHistory from '@/lib/api/med-requests/order-history'
import { useGetInvestigations } from '@/services/investigations/useGetInvestigations'
import { useInvestigationRequestById, useMedicationRequestById } from '@/services/medicationRequests'
import { useLockItemById } from '@/services/useLockItem'
import { useParams } from 'react-router-dom'
import { useAISummary } from './useAISummary'
import type { MedRequest } from '@/types/data'

const usePrepareNextRequest = (enabled = true, isInvestigation = false) => {
  const { id } = useParams<{ id: string }>()

  const { data: medRequests } = useMedRequestFiltering(!isInvestigation)
  const { data: investigations } = useGetInvestigations(
    {},
    {
      enabled: enabled && isInvestigation
    }
  )
  const nextItem = (isInvestigation ? investigations : medRequests)?.find(e => e.id !== id)

  const investigation = useInvestigationRequestById(nextItem?.id ?? '', !!nextItem && enabled && isInvestigation)
  useMedicationRequestById(
    (isInvestigation ? investigation?.data?.medicationRequestId : nextItem?.id) ?? '',
    ((isInvestigation && !!investigation?.data) || !!nextItem) && enabled
  )
  useLockItemById(nextItem?.id ?? '', !!nextItem && enabled)
  usePatientOrderHistory(nextItem?.patient?.id ?? '', !!nextItem && enabled)
  useAISummary(!isInvestigation ? (nextItem as MedRequest) : undefined)

  return nextItem
}

export default usePrepareNextRequest
