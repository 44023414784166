import http from '@/lib/http'
import type { Investigation } from '@/types/data'
import { useQuery, type UseQueryOptions } from '@tanstack/react-query'

export const useGetInvestigations = (
  params?: Record<string, string>,
  options?: Omit<UseQueryOptions<Investigation[]>, 'queryFn' | 'queryKey'>
) => {
  return useQuery<Investigation[]>({
    queryKey: ['investigations', params],
    queryFn: async () => {
      return http.get('/prescriber/investigations', {
        params
      })
    },
    ...options
  })
}
