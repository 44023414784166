import { useToast } from '@/hooks/use-toast'
import http from '@/lib/http'
import type { AdditionalNotesType, Investigation, MedRequest } from '@/types/data'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/services/queryClient'
import { useFilterStore } from '@/lib/stores/filter'
import { resetInvestigationStore } from '@/lib/stores/investigation'

const useInvestigationAction = () => {
  const { toast } = useToast()

  return useMutation({
    mutationKey: ['medRequestAction'],
    mutationFn: ({
      id,
      action,
      message,
      additionalNotes
    }: {
      id: string
      action: 'approve' | 'contact'
      message: string // Either approval reason or contact message
      additionalNotes?: AdditionalNotesType
    }) => {
      return http.post('/prescriber/investigations/update', {
        id,
        action,
        additionalNotes,
        message
      })
    },
    onMutate(variables) {
      const key = useFilterStore.getState().getCacheKey()
      queryClient.setQueryData(key, (old: (MedRequest | Investigation)[]) => old.filter(e => e.id !== variables.id))
      resetInvestigationStore()
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['investigationRequestById'] })
    },
    onError() {
      toast({
        title: 'Error',
        description: 'Error approving medication request'
      })
    }
  })
}

export default useInvestigationAction
